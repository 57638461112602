<template>
    <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar">
        <nav class="uk-navbar-container header-background" style="position: relative; z-index: 980;" uk-navbar="mode: click">
            <div class="uk-navbar-left">
                <ul class="uk-navbar-nav">
                    <li><router-link :to="'/'">Myrobin - Admin</router-link></li>
                    <li>
                        <a class="uk-navbar-toggle" href="#toggle-animation" uk-toggle="target: #toggle-animation; animation: uk-animation-slide-left">
                            <span uk-navbar-toggle-icon></span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="uk-navbar-center">
                <ul class="uk-navbar-nav">
                    <li><b>{{headerTitle}}</b></li>
                </ul>
            </div>

            <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                    <li>
                        <a href="#notif-modal" uk-toggle @click="getNotif">
                            <span class="uk-position-relative" uk-icon="icon: bell; ratio: 1.5" >
                                <span v-if="counter && counter > 0"
                                    class="uk-badge uk-position-absolute uk-position-top-right uk-position-z-index uk-label-default" style="min-width: 17px;height: 17px">
                                    {{counter}}
                                </span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a class="uk-navbar-toggle" href="#">
                            <span class="avatar uk-text-bold">{{stringAvatar}}</span>
                            <span class="uk-margin-small-left">{{fullName}}</span>
                        </a>
                        <div class="uk-navbar-dropdown" uk-toggle>
                            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                                <li><router-link to="/" @click.native="logout"><span class="uk-margin-small-right" uk-icon="icon: sign-out"></span> Log Out</router-link></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <div id="notif-modal" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Notifications</h2>
                </div>

                <div class="uk-modal-body" uk-overflow-auto>
                    <div v-if="isLoading" uk-spinner class="uk-position-center" :ratio="2"></div>
                    <div v-else v-for="(notif, i) in notifications" :key="i" 
                    class="uk-card uk-card-small uk-card-hover uk-card-body uk-margin-bottom uk-drag" 
                    @click="clickNotif(notif)">
                        <div v-if="!notif.is_read" class="uk-card-badge uk-label">New</div>
                        <h3 class="uk-card-title">{{notif.title}}</h3>
                        <p>{{notif.description}}</p>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button :disabled="notifications.length < 1 || isLoading" class="uk-button uk-button-primary" type="button" @click="readAllNotif">Mark all as read</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
} from '@/utils/notification';

export default {
    data() {
        return {
            counter: null,
            notifications: [],
            isLoading: false,
        };
    },
    computed: {
        fullName() {
            return formatter.getFullname();
        },
        stringAvatar() {
            return formatter.textAvatar();
        },
        headerTitle() {
            return formatter.getHeaderTitle(this.$route.fullPath);
        }
    },
    async mounted() {
        const resp = await this.$store.dispatch('profile/getCounter');
        this.counter = resp.unread_notif;
    },
    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout');
        },
        async getNotif() {
            this.isLoading = true;
            this.notifications = await this.$store.dispatch('profile/getNotification');
            this.isLoading = false;
            if (this.counter > 0) {
                const resp = await this.$store.dispatch('profile/getCounter');
                this.counter = resp.unread_notif;
            }
        },
        async clickNotif(notif) {
            if (notif.type === "partner_open_new_job") {
                const resp = await this.$store.dispatch('profile/readNotification', {notification_ids:[`${notif._id}`]});
                if (resp === 'success') {
                    this.$router.push(`/admin/marketing/jobs/detail/${notif.model_id}`);
                }
            } else {
                const resp = await this.$store.dispatch('profile/readNotification', {notification_ids:[`${notif._id}`]});
                if (resp === 'success') {
                    notificationSuccess('Notification has been read!');
                    for (const notification of this.notifications) {
                        if (notification._id.toString() == notif._id.toString()) {
                            notification.is_read = true;
                        }
                    }
                }
            }
            await window.UIkit.modal("#notif-modal").hide();
        },
        async readAllNotif() {
            this.isLoading = true;
            const resp = await this.$store.dispatch('profile/readAllNotification');
            if (resp === 'success') {
                notificationSuccess('All notification has been read!');
                for (const notif of this.notifications) {
                    notif.is_read = true;
                }
            }
            this.isLoading = false;
            await window.UIkit.modal("#notif-modal").hide();
        }
    }
};
</script>

<style scoped>
.header-background {
    padding: 0 10px;
    background-color: #fff;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.uk-navbar-nav > li > a {
    min-height: 60px
}
</style>

<template>
    <div class="uk-background-cover uk-text-left">
        <header-general />

        <div class="uk-grid-collapse" uk-grid>
            <sidebar-general />
            <div class="uk-width-expand@s">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderGeneral from '@/components/globals/headers/General';
import SidebarGeneral from '@/components/globals/sidebars/General';

export default {
    components: {
        HeaderGeneral,
        SidebarGeneral
    }
};
</script>

<style lang="less">
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/vue-datetime/dist/vue-datetime.css";
</style>
